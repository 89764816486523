import type { SendTransactionFrom } from '@algorandfoundation/algokit-utils/types/transaction'
import {
    getApplicationAddress,
    Indexer,
    makeEmptyTransactionSigner,
} from 'algosdk'
import { walletManager } from '../wallet'
import { BlaqEcoClient } from './BlaqEcoClient'
// import { HueSocialClient } from './HueSocialClient'

type BlaqApps = {
    blaqClient?: BlaqEcoClient
    // Future Client Applications
    // hueClient?: HueSocialClient
    // hbcuFan?:
    // blackAmericaTravel?:
    // (Council)?:
    // (News)?:
    // (Streaming)?:
    // (Ticketing)?:
}

/**
 * Indexer client instance
 */
const indexerClient = new Indexer(
    process.env.ALGORAND_INDEXER_TOKEN!,
    process.env.ALGORAND_INDEXER_ADDRESS,
    process.env.ALGORAND_INDEXER_PORT
)

/**
 * Gets the service app clients
 * @returns {BlaqApps} The Blaq app clients for service operations
 */
const getServiceAppClients = (): BlaqApps => {
    const addr = getApplicationAddress(Number(process.env.BLAQ_ID))
    return getAppClient({
        addr,
        signer: makeEmptyTransactionSigner(),
    })
}

/**
 * Gets the user app clients
 * @returns {BlaqApps} The Blaq app clients for user operations
 */
const getUserAppClients = (): BlaqApps => {
    return getAppClient({
        addr: walletManager.activeAddress!,
        signer: walletManager.transactionSigner,
    })
}

/**
 * Gets the app client based on the sender
 * @param {SendTransactionFrom} sender - The transaction sender
 * @returns {BlaqApps} The Blaq app clients
 */
const getAppClient = (sender: SendTransactionFrom): BlaqApps => ({
    blaqClient: new BlaqEcoClient(
        {
            resolveBy: 'id',
            id: Number(process.env.BLAQ_ID),
            sender,
        },
        walletManager.algodClient
    ),
    // Future Client Instantiations
})

export { getServiceAppClients, getUserAppClients, indexerClient, type BlaqApps }
