import { AlgorandClient } from '@algorandfoundation/algokit-utils'
import type { WalletManager } from '@txnlab/use-wallet'
import { BlaqGateManager } from './scripts/blaq_manager'
import { indexerClient } from './scripts/clients'
import MobileNav from './scripts/mobile_nav'
import { walletManager } from './scripts/wallet'
import { loader } from './toolbox'

/**
 * Configuration options for initializing the BlaqPlatform.
 * @typedef {Object} TBlaqPlatform
 * @property {boolean} enableLogging - Flag to enable or disable console logging functionality.
 */
type TBlaqPlatform = {
    enableLogging: boolean
}

/**
 * @title BlaqPlatform - Core platform class for managing Algorand blockchain interactions, wallet connections,
 * and gate access control.
 */
class BlaqPlatform {
    /**
     * Algorand client instance for blockchain interactions.
     */
    private algorand: AlgorandClient = AlgorandClient.fromClients({
        algod: walletManager.algodClient,
        indexer: indexerClient,
    })

    /**
     * Gate manager instance for handling access control.
     */
    private gate: BlaqGateManager = new BlaqGateManager(walletManager)

    /**
     * Wallet manager instance for handling cryptocurrency wallet operations.
     */
    private wallet: WalletManager = walletManager

    /**
     * Initializes the BlaqPlatform instance with optional logging configuration.
     * Sets up event listeners and initializes core components.
     * @param {TBlaqPlatform} options - Configuration options for the platform.
     * @param {boolean} [options.enableLogging=false] - Flag to enable console logging.
     */
    init({ enableLogging }: TBlaqPlatform = { enableLogging: false }) {
        this.loaderObserver()
        this.setLogging(enableLogging)

        document.addEventListener('DOMContentLoaded', async () => {
            MobileNav.init()
            await this.wallet.resumeSessions()
            await this.gate.enter()
        })
    }

    /**
     * Sets up a MutationObserver to monitor loader element class changes
     * and handle popover visibility based on HTMX request states.
     */
    private loaderObserver() {
        const loaderCallback = (mutations: MutationRecord[]) => {
            mutations.forEach(mutation => {
                const el = mutation.target as HTMLDivElement
                if (el.classList.contains('htmx-request')) el.showPopover()
                else el.hidePopover()
            })
        }
        const loaderObserver = new MutationObserver(loaderCallback)

        loaderObserver.observe(loader, {
            attributeFilter: ['class'],
        })
    }

    /**
     * Configures console logging behavior based on the enable flag.
     * When disabled, suppresses all console.log outputs.
     * @param {boolean} enable - Flag to enable or disable console logging.
     */
    private setLogging(enable: boolean) {
        const originalLog = console.log

        console.log = function (...args: any[]) {
            if (enable) {
                originalLog.apply(console, args)
            }
        }
    }
}

const BP = new BlaqPlatform()
BP.init({ enableLogging: true })

export { BP }
