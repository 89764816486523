const hamburger = document.getElementById('hamburger') as HTMLButtonElement

const toggleMobileNav = (action?: NavAction) => {
    const isActive = action
        ? action === 'show'
        : document.body.dataset.mmActive !== 'true'
    document.body.dataset.mmActive = isActive.toString()
    hamburger.classList.toggle('is-active', isActive)
}

const init = () => {
    hamburger.addEventListener('click', () => toggleMobileNav())
}

export default { init, toggleMobileNav }
