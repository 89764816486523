import {
    AlgorandClient,
    getBoxReference,
} from '@algorandfoundation/algokit-utils'
import { AlgoAmount } from '@algorandfoundation/algokit-utils/types/amount'
import type { WalletManager } from '@txnlab/use-wallet'
import { decodeAddress, decodeUint64 } from 'algosdk'
import htmx from 'htmx.org'
import { allowAppDrawer, loader } from '../toolbox'
import {
    getServiceAppClients,
    getUserAppClients,
    indexerClient,
} from './clients'
import type { BlaqEcoClient } from './clients/BlaqEcoClient'
import { buildWalletUI } from './wallet'

/** Blaq Card status types */
type TBlaqCardStatus = 'request' | 'pickup'

/** Dialog configuration type */
type TDialogConfig = {
    title: string
    subtitle: string
    buttonContent: string
}

/**
 * Manages Blaq ecosystem interactions and UI
 */
export class BlaqGateManager {
    private blaqAppID: number = Number(process.env.BLAQ_ID)
    private gate: HTMLElement =
        document.getElementById('gate') || document.createElement('div')
    private exits: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
        '[data-action="dialog-exit"]'
    )
    private algorand: AlgorandClient
    private ubc: BlaqEcoClient
    private sbc: BlaqEcoClient
    blaqCard?: number
    blaqCardActive: boolean = false
    loader: HTMLDivElement = loader

    /**
     * @param {WalletManager} wallet - The wallet manager instance
     */
    constructor(private wallet: WalletManager) {
        this.algorand = AlgorandClient.fromClients({
            algod: wallet.algodClient,
            indexer: indexerClient,
        })
        this.sbc = this.ubc = getServiceAppClients().blaqClient!

        this.handleExit = this.handleExit.bind(this)
        this.openDialog = this.openDialog.bind(this)
        wallet.subscribe(this.handleWalletStateChange.bind(this))
    }

    /**
     * Handles user entry into the Blaq ecosystem
     */
    async enter(): Promise<void> {
        if (!this.wallet.activeAddress) return this.handleConnection()

        this.ubc = getUserAppClients().blaqClient!
        this.blaqCardActive = await this.isBlaqCardActive()

        this.setExits()

        this.blaqCardActive
            ? this.checkWelcomeStatus()
            : this.handleBlaqCardIssuance()

        await this.getUserApplicationList()
        allowAppDrawer(this.blaqCardActive)
    }

    /**
     * Checks if the Blaq Card is active
     */
    private async isBlaqCardActive(): Promise<boolean> {
        const boxName = new Uint8Array([
            ...new TextEncoder().encode('BC_'),
            ...decodeAddress(this.wallet.activeAddress!).publicKey,
        ])
        const boxRef = getBoxReference(boxName)

        const blaqCard = await this.algorand.client.indexer
            .lookupApplicationBoxByIDandName(this.blaqAppID, boxRef.name)
            .do()
            .then(rsp => decodeUint64(rsp.value, 'bigint'))
            .catch(() => 0)

        this.blaqCard = Number(blaqCard) || 0
        if (!blaqCard) return false

        const {
            returns: [isActive],
        } = await this.sbc
            .compose()
            .isBlaqCardActive(
                { blaqCard: this.blaqCard! },
                {
                    accounts: [this.wallet.activeAddress!],
                    assets: [this.blaqCard!],
                    boxes: [boxRef],
                }
            )
            .simulate({ allowEmptySignatures: true })

        return isActive
    }

    /**
     * Handles connection process
     */
    private async handleConnection(): Promise<void> {
        const handleAfterSwap = () => {
            buildWalletUI(
                this.gate.querySelector('figure.wallet') as HTMLDivElement
            )
            this.gate.classList.add('show')
            this.gate
                .querySelector('[data-action="dialog-connect"]')
                ?.addEventListener('click', e =>
                    this.openDialog(e.target as HTMLButtonElement)
                )
            this.gate.removeEventListener('htmx:afterSwap', handleAfterSwap)
        }

        this.blaqCardActive = false
        this.gate.addEventListener('htmx:afterSwap', handleAfterSwap)
        await htmx.ajax('get', '/api/gate/connect', this.gate)
    }

    /**
     * Handles Blaq Card issuance process
     */
    private async handleBlaqCardIssuance(): Promise<void> {
        const handleAfterSwap = () => {
            this.gate.classList.add('show')
            const btnAction = this.gate.querySelector(
                '[data-action="dialog-membership"]'
            ) as HTMLButtonElement
            btnAction?.addEventListener('click', () =>
                this.openDialog(btnAction)
            )
            this.openDialog(btnAction)
            this.gate.removeEventListener('htmx:afterSwap', handleAfterSwap)
        }
        this.gate.addEventListener('htmx:afterSwap', handleAfterSwap)
        await htmx.ajax('get', '/api/gate/membership', this.gate)
    }

    /**
     * Handles the welcome process
     */
    private async handleWelcome(): Promise<void> {
        const handleAfterSwap = () => {
            this.gate.classList.add('show')
            const dialog = this.gate.querySelector(
                '[data-action="dialog-welcome"]'
            ) as HTMLDialogElement
            dialog.showModal()
            dialog.addEventListener('click', this.dialogClickHandler)
            this.gate.removeEventListener('htmx:afterSwap', handleAfterSwap)
        }
        this.gate.addEventListener('htmx:afterSwap', handleAfterSwap)
        await htmx.ajax('get', '/api/gate/welcome', this.gate)
    }

    /**
     * Handles exit process
     */
    private async handleExit(): Promise<void> {
        const handleAfterSwap = () => {
            this.gate.classList.add('show')
            const btnAction = document.body.querySelector(
                '[data-action="dialog-exit"]'
            ) as HTMLButtonElement
            btnAction?.addEventListener('click', () =>
                this.openDialog(btnAction)
            )
            this.openDialog(btnAction)
            this.gate.removeEventListener('htmx:afterSwap', handleAfterSwap)
        }
        this.gate.addEventListener('htmx:afterSwap', handleAfterSwap)
        await htmx.ajax('get', '/api/gate/exit', {
            target: this.gate,
            swap: 'beforeend',
        })
    }

    /**
     * Opens a dialog
     * @param {HTMLButtonElement} [btnAction] - The button that triggered the dialog
     */
    private async openDialog(btnAction?: HTMLButtonElement): Promise<void> {
        if (!btnAction) return

        const actionType = btnAction
            .getAttribute('data-action')
            ?.replace('dialog-', '')
        const dialogId = `diag_${actionType}`
        const dialog = this.gate.querySelector(
            `#${dialogId}`
        ) as HTMLDialogElement | null

        if (!dialog) {
            if (actionType !== 'exit') {
                console.warn(`Dialog with id ${dialogId} not found`)
            }
            return
        }

        dialog.showModal()
        dialog.addEventListener('click', this.dialogClickHandler)

        if (actionType === 'membership') {
            await this.handleMembershipDialog(dialog)
        }

        btnAction.removeEventListener('click', () => this.openDialog(btnAction))
    }

    /**
     * Handles membership dialog
     * @param {HTMLDialogElement | null} dialog - The dialog element
     */
    private async handleMembershipDialog(
        dialog: HTMLDialogElement | null
    ): Promise<void> {
        if (this.blaqCard) return this.setBlaqCardButtons('pickup')

        const isGateOpen = await this.sbc
            .getGlobalState()
            .then(rsp => rsp.go?.asByteArray()[0])

        isGateOpen
            ? this.setBlaqCardButtons('request')
            : this.setClosedGateMessage(dialog)
    }

    /**
     * Sets closed gate message in dialog
     * @param {HTMLDialogElement | null} dialog - The dialog element
     */
    private setClosedGateMessage(dialog: HTMLDialogElement | null): void {
        if (!dialog) return

        const h1 = dialog.querySelector('h1')
        const h2 = dialog.querySelector('h2')

        if (h1) h1.innerHTML = 'Community Gate</br>is Closed'
        if (h2) h2.innerText = 'We will reopen soon.'
    }

    /**
     * Sets up Blaq Card buttons based on status
     * @param {TBlaqCardStatus} status - The current Blaq Card status
     */
    private setBlaqCardButtons(status: TBlaqCardStatus): void {
        const dialog = this.gate.querySelector(
            'dialog'
        ) as HTMLDialogElement | null
        if (!dialog) {
            console.error('Dialog element not found')
            return
        }

        const config: Record<TBlaqCardStatus, TDialogConfig> = {
            request: {
                title: 'Join the Community',
                subtitle: 'Request your Blaq Card',
                buttonContent:
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.308 512.308"><path d="M505.878 36.682 110.763 431.69a63.318 63.318 0 0 0 27.413 6.4h67.669a21.187 21.187 0 0 1 15.083 6.251l36.672 36.651a106.043 106.043 0 0 0 75.157 31.317 107.276 107.276 0 0 0 34.261-5.653c38.05-12.475 65.726-45.46 71.403-85.099l72.085-342.4a63.12 63.12 0 0 0-4.628-42.475zM433.771 1.652 92.203 73.61C33.841 81.628-6.971 135.44 1.047 193.802a106.67 106.67 0 0 0 30.228 60.885l36.651 36.651a21.336 21.336 0 0 1 6.251 15.104v67.669a63.315 63.315 0 0 0 6.4 27.413L475.627 6.41a62.525 62.525 0 0 0-41.856-4.758z"/></svg>',
            },
            pickup: {
                title: 'Welcome to the Community',
                subtitle: 'Pick-up your Blaq Card',
                buttonContent:
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12v9c0 1.654-1.346 3-3 3H3c-1.654 0-3-1.346-3-3v-9h8v2c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2v-2h8Zm-12-1.001c.512 0 1.024-.195 1.414-.585l3.293-3.293-1.414-1.414L13 8V0h-2v8L8.707 5.707 7.293 7.121l3.293 3.293c.39.39.902.585 1.414.585Z"/></svg>',
            },
        }

        const { title, subtitle, buttonContent } = config[status]

        dialog.dataset.card = status

        const h1 = dialog.querySelector('h1')
        const h2 = dialog.querySelector('h2')
        const button = dialog.querySelector('button')
        const pBlaqcard = dialog.querySelector('p.blaqcard')

        if (h1) h1.textContent = title
        if (h2) h2.textContent = subtitle
        if (button) button.innerHTML = buttonContent
        if (pBlaqcard)
            pBlaqcard.textContent =
                status === 'pickup' && this.blaqCard ? `#${this.blaqCard}` : ''
    }

    /**
     * Handles dialog click events
     * @param {Event} e - The click event
     */
    private dialogClickHandler = (e: Event): void => {
        const target = e.target as HTMLElement
        if (target.classList.contains('fi-tr-circle-xmark')) {
            this.closeDialog(target)
        } else if (target.localName === 'button') {
            const card = target.parentElement!.getAttribute('data-card')
            if (card === 'request') this.requestBlaqCard()
            if (card === 'pickup') this.pickUpBlaqCard()
            if (card === 'exit') this.exit()

            if (target.hasAttribute('data-action')) {
                this.closeDialog(target)
            }
        }
    }

    /**
     * Requests a Blaq Card
     */
    private async requestBlaqCard(): Promise<void> {
        this.loader.classList.add('htmx-request')
        try {
            const { appAddress } = await this.ubc.appClient.getAppReference()
            const {
                returns: [gateFeeAlgo],
            } = await this.sbc
                .compose()
                .getGateFeeByAlgo({})
                .simulate({ allowEmptySignatures: true })

            const gateFeeTxn = await this.algorand.transactions.payment({
                sender: this.wallet.activeAddress!,
                receiver: appAddress,
                amount: AlgoAmount.MicroAlgos(Number(gateFeeAlgo)),
                extraFee: AlgoAmount.MicroAlgos(1_000),
                signer: this.wallet.transactionSigner,
            })

            const { return: blaqCard } =
                await this.ubc.optIn.optInToApplication({ gateFeeTxn })
            if (blaqCard) {
                this.blaqCard = Number(blaqCard)
                this.setBlaqCardButtons('pickup')
            }
        } finally {
            this.loader.classList.remove('htmx-request')
        }
    }

    /**
     * Picks up a Blaq Card
     */
    private async pickUpBlaqCard(): Promise<void> {
        this.loader.classList.add('htmx-request')
        try {
            const blaqCardOptInTxn =
                await this.algorand.transactions.assetTransfer({
                    sender: this.wallet.activeAddress!,
                    receiver: this.wallet.activeAddress!,
                    assetId: BigInt(this.blaqCard!),
                    amount: BigInt(0),
                    extraFee: AlgoAmount.MicroAlgos(1_000),
                    signer: this.wallet.transactionSigner,
                })
            await this.ubc.completeOptIn({ blaqCardOptInTxn })
            await this.enter()
        } finally {
            this.loader.classList.remove('htmx-request')
        }
    }

    /**
     * Checks welcome status and handles accordingly
     */
    private async checkWelcomeStatus(): Promise<void> {
        const welcome = localStorage.getItem('welcome')
        if (!welcome || this.isWelcomeExpired(welcome)) {
            localStorage.setItem('welcome', new Date().toISOString())
            return this.handleWelcome()
        }
        Array.from(this.gate.children).forEach(child => {
            if (!(child.tagName === 'DIALOG' && child.id === 'diag_exit'))
                child.remove()
        })
    }

    /**
     * Checks if welcome message has expired
     * @param {string} welcomeDate - The date of the last welcome message
     */
    private isWelcomeExpired(welcomeDate: string): boolean {
        const daysDifference =
            (new Date().getTime() - new Date(welcomeDate).getTime()) /
            (1000 * 60 * 60 * 24)
        return daysDifference > 30
    }

    /**
     * Closes the dialog
     * @param {HTMLElement} el - The element that triggered the close action
     */
    private closeDialog(el: HTMLElement): void {
        const dialog = el.closest('dialog')
        if (dialog) {
            dialog.close()
            dialog.removeEventListener('click', this.dialogClickHandler)
            if (dialog.id === 'diag_exit') {
                dialog.remove()
            }
        }
    }

    /**
     * Sets up exit buttons
     */
    private setExits(): void {
        this.exits.forEach(btn => {
            btn.classList.add('connected')
            btn.title = 'Connected'
            if (this.blaqCardActive) {
                localStorage.setItem('blaqCard', this.blaqCard!.toString())
                btn.classList.add('active')
                btn.title = `Active: Blaq Card #${this.blaqCard}`
            }
            btn.removeEventListener('click', this.handleExit)
            btn.addEventListener('click', this.handleExit)
        })
    }

    /**
     * Handles the exit process
     */
    private exit = async (): Promise<void> => {
        localStorage.clear()
        this.exits.forEach(btn => btn.classList.remove('connected', 'active'))
        await this.wallet.disconnect()
    }

    private async getUserApplicationList() {
        const appDrawers = document.querySelectorAll(
            'ul.apps'
        ) as NodeListOf<HTMLUListElement>

        for (let i = 0; i < appDrawers.length; i++) {
            console.log('bing')
            await htmx.ajax(
                'get',
                `/api/applist/${this.wallet.activeAddress}`,
                appDrawers[i] as HTMLUListElement
            )
        }
    }

    /**
     * Handles wallet state changes
     */
    private async handleWalletStateChange() {
        document.querySelectorAll('dialog').forEach(dialog => dialog.close())

        // await new Promise(resolve => setTimeout(resolve, 2000))

        await this.enter()
        allowAppDrawer(this.blaqCardActive)
    }
}
