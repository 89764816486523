const loader = document.getElementById('loader') as HTMLDivElement

const allowAppDrawer = (status: boolean) =>
    (document.body.dataset.connected = String(status))

// type TBlaqState = {
//     blaqCard?: number
//     blaqCardActive: boolean
// }

// class BlaqStore {}

export { allowAppDrawer, loader }
