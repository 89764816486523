import { Config } from '@algorandfoundation/algokit-utils'
import {
    NetworkId,
    WalletId,
    WalletManager,
    type WalletManagerConfig,
} from '@txnlab/use-wallet'
import { WalletComponent } from './wallet_component'

Config.configure({ populateAppCallResources: true })

let WMConfig: WalletManagerConfig
if (process.env.RAILWAY_ENVIRONMENT_NAME === 'genesis') {
    WMConfig = {
        wallets: [
            WalletId.PERA,
            WalletId.DEFLY,
            // WalletId.EXODUS,
            // WalletId.KIBISIS,
            // {
            //     id: WalletId.WALLETCONNECT,
            //     options: {
            //         projectId: '2b2aa1af15579cc44004648d9be296cd',
            //         themeVariables: {
            //             '--wcm-z-index': '300',
            //             '--wcm-overlay-backdrop-filter': 'blur(10px)',
            //             '--wcm-background-border-radius': '0.5rem',
            //             '--wcm-container-border-radius': '0.5rem',
            //             '--wcm-button-border-radius': '0.5rem',
            //             '--wcm-icon-button-border-radius': '0.5rem',
            //             '--wcm-wallet-icon-border-radius': '0.5rem',
            //         },
            //     },
            // },
            // WalletId.LIQUID,
        ],
        network: NetworkId.TESTNET,
    }
} else {
    WMConfig = {
        wallets: [WalletId.KMD],
        network: NetworkId.LOCALNET,
    }
}

/**
 * Wallet Manager instance configured with multiple wallet types and set to use the local network.
 */
const walletManager: WalletManager = new WalletManager(WMConfig)

/**
 * Initializes the wallet interface and components.
 * @param {HTMLDivElement} el - The root element to append wallet components to.
 */
const buildWalletUI = (el: HTMLDivElement): void => {
    /**
     * Creates and appends the wallet tabs container.
     * @returns {HTMLUListElement} The created wallet tabs element.
     */
    const createWalletTabs = (): HTMLUListElement => {
        const walletTabs = document.createElement('ul')
        walletTabs.className = 'wallet-tabs blur'
        el.appendChild(walletTabs)
        return walletTabs
    }

    /**
     * Creates the wallet actions container.
     * @returns {HTMLDivElement} The created wallet actions element.
     */
    const createWalletActions = (): HTMLDivElement => {
        const walletActions = document.createElement('div')
        walletActions.classList.add('wallet-actions', 'blur')
        walletActions.innerHTML = '<span class="default">Select Wallet</span>'
        return walletActions

        // const defaultMsg = document.createElement('span')
        // defaultMsg.className = 'default'
        // defaultMsg.innerText = 'Select Wallet'

        // walletActions.appendChild(defaultMsg)
        // return walletActions
    }

    /**
     * Initializes wallet components and appends them to the wallet tabs.
     * @param {HTMLUListElement} walletTabs - The wallet tabs container.
     * @param {HTMLDivElement} walletActions - The wallet actions container.
     * @returns {WalletComponent[]} Array of initialized wallet components.
     */
    const initializeWalletComponents = (
        walletTabs: HTMLUListElement,
        walletActions: HTMLDivElement
    ): WalletComponent[] => {
        return walletManager.wallets.map(wallet => {
            const component = new WalletComponent(wallet, walletActions)
            walletTabs.appendChild(component.walletTab)
            return component
        })
        // const walletComponents = walletManager.wallets.map(
        //     wallet => new WalletComponent(wallet, walletManager, walletActions)
        // )

        // walletComponents.forEach(component => {
        //     walletTabs.appendChild(component.walletTab)
        // })

        // return walletComponents
    }

    /**
     * Sets up event listeners for cleanup.
     * @param {WalletComponent[]} walletComponents - Array of wallet components.
     */
    const setupEventListeners = (walletComponents: WalletComponent[]): void => {
        window.addEventListener('beforeunload', () => {
            walletComponents.forEach(component => component.destroy())
        })
    }

    // Main initialization flow
    // createActiveNetwork()
    const walletTabs = createWalletTabs()
    const walletActions = createWalletActions()
    el.appendChild(walletActions)
    const walletComponents = initializeWalletComponents(
        walletTabs,
        walletActions
    )
    setupEventListeners(walletComponents)
}

export { buildWalletUI, walletManager }
