import { BaseWallet } from '@txnlab/use-wallet'
import { loader } from '../toolbox'

/**
 * Manages the UI and functionality for a single wallet connection.
 */
export class WalletComponent {
    private static activeInstance: WalletComponent | null = null
    private unsubscribe?: () => void
    // private algoClient: AlgorandClient
    public walletTab: HTMLLIElement
    private indicator: HTMLDivElement | null = loader
    private walletLinks: Record<string, string> = {
        pera: 'https://perawallet.app/',
        defly: 'https://defly.app/',
        exodus: 'https://www.exodus.com/',
        kibisis: 'https://kibis.is/',
    }

    /**
     * @param {BaseWallet} wallet - The wallet instance to manage
     * @param {HTMLElement} walletActionContainer - The container element for wallet actions
     */
    constructor(
        private wallet: BaseWallet,
        private walletActionContainer: HTMLElement
    ) {
        // this.algoClient = AlgorandClient.fromClients({
        //     algod: walletManager.algodClient,
        // })
        this.walletTab = document.createElement('li')
        this.walletTab.className = 'wallet-tab'
        this.walletTab.dataset.selected = 'false'

        this.unsubscribe = wallet.subscribe(state => {
            // Handle state changes if needed
        })

        this.renderTab()
        this.addEventListeners()
    }

    /**
     * Connects the wallet and sets up the user's profile.
     * @returns {Promise<void>}
     */
    connect = async (): Promise<void> => {
        try {
            // this.indicator?.classList.add('htmx-request')
            await this.wallet.connect()
            if (!this.wallet.activeAddress)
                throw new Error('[App] No active account')
        } catch (error) {
            this.handleConnectionError(error)
        } finally {
            this.finalizeConnection()
        }
    }

    /**
     * Sets the current wallet as active.
     */
    setActive = (): void => {
        this.wallet.setActive()
    }

    /**
     * Changes the active account for the wallet.
     * @param {Event} event - The change event from the account selection dropdown
     */
    setActiveAccount = (event: Event): void => {
        const target = event.target as HTMLSelectElement
        this.wallet.setActiveAccount(target.value)
    }

    /**
     * Toggles the visibility of wallet tabs.
     * @param {HTMLLIElement} selectedTab - The tab to be selected
     */
    toggleTabs(selectedTab: HTMLLIElement): void {
        const tabs =
            this.walletTab
                .closest('ul.wallet-tabs')
                ?.querySelectorAll('li.wallet-tab') || []
        tabs.forEach(tab => ((tab as HTMLLIElement).dataset.selected = 'false'))
        selectedTab.dataset.selected = 'true'
        WalletComponent.activeInstance?.removeActionEventListener()
        WalletComponent.activeInstance = this
        this.renderActions()
    }

    /**
     * Cleans up event listeners and subscriptions.
     */
    destroy(): void {
        this.unsubscribe?.()
        this.walletTab.removeEventListener('click', this.handleTabClick)
        this.walletTab.removeEventListener('change', this.handleChange)
    }

    /**
     * Renders the wallet tab in the UI.
     */
    private renderTab(): void {
        this.walletTab.innerHTML = `
            <button type="button" class="selector" title="${this.wallet.metadata.name}" data-wallet="${this.wallet.metadata.name}">
                <img src="${this.wallet.metadata.icon}" class="icon" alt="${this.wallet.metadata.name}" />
            </button>
        `
    }

    /**
     * Renders the wallet actions in the UI.
     */
    private renderActions(): void {
        const walletLink =
            this.walletLinks[this.wallet.metadata.name.toLowerCase()]
        this.walletActionContainer.dataset.wallet = this.wallet.metadata.name
        this.walletActionContainer.innerHTML = `
            ${
                this.wallet.isActive
                    ? '<button type="button" data-action="transaction" class="action transaction">Send Transaction</button>'
                    : ''
            }
            ${
                !this.wallet.isActive && this.wallet.isConnected
                    ? '<button type="button" data-action="set-active" class="action switch">Set Active</button>'
                    : ''
            }
            ${this.renderAccountSelector()}
            <button type="button" data-action="connect" class="action connection">
                ${
                    this.wallet.isConnected
                        ? '<i class="fi fi-tr-link-slash-alt"></i>'
                        : `<span class="action">Connect via ${this.wallet.metadata.name}</span>`
                }
            </button>
            ${
                walletLink
                    ? `<a href="${walletLink}" target="_blank" title="${this.wallet.metadata.name}" class="link">${walletLink}</a>`
                    : ''
            }
        `
        this.walletActionContainer.addEventListener(
            'click',
            this.handleActionClick
        )
    }

    /**
     * Renders the account selector dropdown.
     * @returns {string} HTML string for the account selector
     */
    private renderAccountSelector(): string {
        if (!this.wallet.isActive || !this.wallet.accounts.length) return ''
        return `
            <div class="wallet-accounts">
                <select>
                    ${this.wallet.accounts
                        .map(
                            (account, i) => `
                        <option value="${account.address}" ${
                                account.address ===
                                this.wallet.activeAccount?.address
                                    ? 'selected'
                                    : ''
                            }>
                            ${i + 1}. ${this.truncateString(account.address)}
                        </option>
                    `
                        )
                        .join('')}
                </select>
            </div>
        `
    }

    /**
     * Truncates a string for display purposes.
     * @param {string} str - The string to truncate
     * @returns {string} The truncated string
     */
    private truncateString(str: string): string {
        return `${str.slice(0, 14)}&hellip;`
    }

    /**
     * Adds event listeners to the wallet component.
     */
    private addEventListeners(): void {
        this.walletTab.addEventListener('click', this.handleTabClick)
        this.walletTab.addEventListener('change', this.handleChange)
    }

    /**
     * Handles click events on the wallet tab.
     */
    private handleTabClick = (e: Event): void => {
        const tabButton = (e.target as HTMLElement).closest('button.selector')
        if (tabButton) this.toggleTabs(this.walletTab)
    }

    /**
     * Handles click events on wallet action buttons.
     */
    private handleActionClick = (e: Event): void => {
        const actionButton = (e.target as HTMLElement).closest(
            'button.action'
        ) as HTMLButtonElement
        if (
            actionButton?.dataset.action === 'connect' &&
            actionButton.closest('.wallet-actions') ===
                this.walletActionContainer
        ) {
            this.connect()
        }
    }

    /**
     * Removes the action click event listener.
     */
    private removeActionEventListener(): void {
        this.walletActionContainer.removeEventListener(
            'click',
            this.handleActionClick
        )
    }

    /**
     * Handles change events on the wallet tab.
     */
    private handleChange = (e: Event): void => {
        if ((e.target as HTMLElement).tagName.toLowerCase() === 'select')
            this.setActiveAccount(e)
    }

    /**
     * Manages errors that occur during the connection process.
     * @param {unknown} error - The error encountered during connection
     */
    private handleConnectionError(error: unknown): void {
        console.error('Connection error:', error)
        if (error instanceof Error) {
            console.error('Error message:', error.message)
            console.error('Error stack:', error.stack)
        }
        this.wallet.disconnect()
        localStorage.clear()
    }

    /**
     * Completes the connection process by updating UI and navigation.
     */
    private finalizeConnection(): void {
        this.indicator?.classList.remove('htmx-request')
    }
}
